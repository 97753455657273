import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/SEO'

function AboutPage() {
  return (
    <Layout>
      <SEO title="About" />

      <div>ABOUT CONTENT</div>
    </Layout>
  )
}

export default AboutPage
